import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/v2/templates/Layout';
import { Section } from '../components/v2/templates/Section';
import useStoryblok from '../lib/storyblok';
import Bloks from '../components/atoms/Bloks';
import Tabs from '../components/v2/organisms/Tabs';
import { StoryblokPage } from '../types';

const Home: StoryblokPage = ({ location, data }) => {
  let story = useStoryblok(data.storyblokEntry, location);
  const { content } = story;

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabClick = (index: number) => {
    setSelectedTabIndex(index);
  };
  return (
    <Layout
      location={location}
      headerData={content.header}
      footerData={content.footer}
      headerVisibleButtonIndex={selectedTabIndex}
    >
      <Bloks bloks={content.SEO} />
      <Section noGrid>
        <Bloks bloks={content.hero} />
      </Section>
      <Bloks bloks={content.sections} />

      {content.tabs && content.tabs.length > 0 && (
        <Tabs onClick={handleTabClick} bloks={content.tabs} />
      )}

      <Bloks bloks={content.sectionsAfterTabs} />
    </Layout>
  );
};

export default Home;

export const query = graphql`
  query Home {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
    }
  }
`;
